import React from "react";
import { Link } from "gatsby";
import StatBox from "../../components/stat-box";
import PagePhoto from "../../components/page-photo";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import HeroPage from "../../components/hero-page";
import AddressMckean from "../../components/address-mckean";
import StatBoxes from "../../components/stat-boxes";
import ServiceList from "../../components/service-list";
import { postSecondary } from "../../data/post-secondary";
import { education } from "../../data/education";
import { hospitals } from "../../data/hospitals";
import { chambers } from "../../data/chambers";
import { airports } from "../../data/airports";
import ExplorePhotos from "../../components/explore-photos"
import Seo from "../../components/seo";

const McKean = ({data}) => {
  const photos = data.allMdx.nodes;

  return (
    <>
      <Seo
        title="McKean County, North Central Pennsylvania"
        description="This hometown kind of place is a fisherman's dream and a hunter's paradise deserving accolades for the hills, forests and streams that lie within its boundaries."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="McKean County"
        caption="Kinzua Skywalk in McKean County"
        position="25%"
      />

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Pristine, breathtaking and stunning in all seasons.</h2>
          </div>

          <div className="intro-text">
            <p>
              This hometown kind of place is a fisherman's dream and a hunter's
              paradise deserving accolades for the hills, forests and streams
              that lie within its boundaries.
            </p>
          </div>
        </section>

        <StatBoxes>
          <StatBox number="41,806" text="Total population" />
          <StatBox number="45,261" text="Per capita income" />
          <StatBox number="Bradford" text="Largest city" />
        </StatBoxes>
      </main>

      <div className="county-employers">
        <section>
          <div className="employers-table-container">
            <h2>Top employers in McKean County</h2>

            <table className="employers">
              <tr>
                <th>Employer</th>
                <th>Industry</th>
              </tr>

              <tr>
                <td>Zippo Manufacturing Co</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Federal Government</td>
                <td></td>
              </tr>

              <tr>
                <td>Bradford Regional Medical Center</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Bradford Area School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>American Refining Group Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>University of Pittsburgh</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>Wal-Mart Associates Inc</td>
                <td>Retail</td>
              </tr>

              <tr>
                <td>Ardagh Glass Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>McKean County</td>
                <td></td>
              </tr>

              <tr>
                <td>Allegheny Bradford Corporation</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Beacon Light Behavioral Health System</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Journey Health System</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>
            </table>
          </div>

          <div className="employers-photos">
            <PagePhoto
              image={data.employersImage1.sharp.image}
              caption="The Guidance Center, McKean County"
            />
            <PagePhoto
              image={data.employersImage2.sharp.image}
              caption="Zippo Manufacturing Company, McKean County"
            />

            <PagePhoto
              image={data.employersImage3.sharp.image}
              caption="Beacon Light Behavioral Health System"
            />

          </div>
        </section>
      </div>
      <main>
        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="Zook Motors, McKean County"
          address=<AddressMckean />
        />

        <section>
          <h2>County Services</h2>

          <ServiceList
            title="Education"
            image={data.educationImage.sharp.fluid}
            position="40%"
            county="McKean"
            data={education}
            cols={2}
            headingText="School"
          />

          <ServiceList
            title="Post Secondary Education"
            image={data.postSecondary.sharp.fluid}
            position="42%"
            county="McKean"
            data={postSecondary}
            cols={2}
            headingText="School"
          />

          {/* Start Medical / Community Services */}
          <div className="services-body">
            <div className="services-col">
              <ServiceList
                title="Hospitals"
                image={data.medicalImage.sharp.fluid}
                position="30%"
                county="McKean"
                data={hospitals}
                headingText="Facility"
              />
            </div>
            <div className="services-col">
              <ServiceList
                title="Chambers of Commerce"
                image={data.businessImage.sharp.fluid}
                position="55%"
                county="McKean"
                data={chambers}
                headingText="Service"
              />
            </div>
          </div>

          <ServiceList
            title="Airports"
            image={data.airportImage.sharp.fluid}
            position="80%"
            county="McKean"
            data={airports}
            headingText="Airport"
            singleHeading={true}
            cols={2}
          />
        </section>

        <ExplorePhotos photos={photos} county="McKean" />
      </main>
    </>
  );
};

export const query = graphql`
  query {
    educationImage: file(
      relativePath: { eq: "neonbrand-zFSo6bnZJTw-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    postSecondary: file(
      relativePath: { eq: "clay-banks-GX8KBbVmC6c-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(relativePath: { eq: "kinzua-skywalk-summer-1665.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "kinzua-skywalk-summer-1665.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

    employersImage1: file(relativePath: { eq: "DrHalbauerNeurostarTMS.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    employersImage2: file(relativePath: { eq: "zippo.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    employersImage3: file(relativePath: { eq: "BeaconLight_StaffSuicidePreventionTshirts.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    careerImage: file(relativePath: { eq: "careerlink-photo-mckean.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    medicalImage: file(
      relativePath: { eq: "olga-guryanova-tMFeatBSS4s-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    businessImage: file(
      relativePath: { eq: "arlington-research-nFLmPAf9dVc-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    airportImage: file(relativePath: { eq: "BradfordAprt_01.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    # slideshow photos
    allMdx(filter: { frontmatter: { county: { eq: "mckean" } } }) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
export default McKean;
