import React from "react";

const AddressMckean = () => {
  return (
    <>
      <h3>
        McKean County PA CareerLink<sup>&reg;</sup>
      </h3>
      <div>
        <p>
          40 Davis Street
          <br />
          Bradford, PA 16701
        </p>
        <p>
          Phone:
          <span>1-844-PACALINK</span>
        </p>
      </div>
    </>
  );
};

export default AddressMckean;
